import { populateAppointment } from '../scheduleCommon/utils.js'
import { DateHelpers } from '@/helpers/DateHelpers'

export default {

  _insertTo (inst, appointment, cell, userId) {
    const god = this

    if (typeof inst.options.onFree !== 'function') return

    const params = {
      date: cell.date,
      time: cell.time,
      user_id: userId,
    }
    const populatedApp =
      god._setAppointmentTime(inst, populateAppointment({ ...appointment, ...params }))
    populatedApp._worktime = god._getWorkTime(
      inst,
      populatedApp.date,
      populatedApp.user_id,
      populatedApp._roughTime,
      populatedApp._endTime)
    inst.options.onFree(populatedApp, god._getParams(inst))
    god._clearState()
  },

  _moveFromWaitingList (inst, appointment, cell, userId) {
    const userWorkTime = inst._schedules[cell.date][cell.cabinet_id][userId]

    if (this._checkAppointmentParams(inst, appointment, cell, userWorkTime)) {
      appointment.time = cell.time
      appointment.user_id = userId
      appointment.date = cell.date
      appointment.clinic_id = inst.options.currentClinicId

      inst.options.onMoveFromWaitingList(appointment)
    } else {
      this._showInvalidReceptionTimeModal(inst)
    }

    this._clearState()
  },

  _moveTo (inst, appointment, cell, userId) {
    const god = this

    const userWorkTime = inst._schedules[cell.date][cell.cabinet_id][userId]

    if (god._checkAppointmentParams(inst, appointment, cell, userWorkTime)) {
      appointment.time = cell.time
      appointment.user_id = userId
      appointment.date = cell.date
      appointment.clinic_id = inst.options.currentClinicId

      god._patchAppointment(inst, appointment)
      god._clearState()

      inst.options.closeInsert()

      return true
    }
    god._showInvalidReceptionTimeModal(inst)
    god._clearState()

    return false
  },

  _cloneTo (inst, appointment, cell, userId) {
    const god = this

    const clone = { ...appointment }
    const userWorkTime = inst._schedules[cell.date][cell.cabinet_id][userId]

    if (god._checkAppointmentParams(inst, clone, cell, userWorkTime)) {
      clone.id = null
      clone.time = cell.time
      clone.date = cell.date
      clone.user_id = userId
      clone.order_id = -1
      clone.status = 'need_approval'
      clone.clinic_id = inst.options.currentClinicId

      const entryTypeIds = clone.entry_types.map((e) => e.id)

      if (entryTypeIds.length) {
        god._showCopyAppointmentWithServicesModal(
          inst,
          true,
          () => {
            clone.entry_type_ids = entryTypeIds
            god._createAppointment(inst, clone)
            god._showCopyAppointmentWithServicesModal(inst, false)
            inst.options.closeInsert()
          },
          () => {
            god._createAppointment(inst, clone)
            god._showCopyAppointmentWithServicesModal(inst, false)
            inst.options.closeInsert()
          })
      } else {
        god._createAppointment(inst, clone)
        inst.options.closeInsert()
      }
    } else { god._showInvalidReceptionTimeModal(inst) }

    god._clearState()
  },

  _checkAppointmentParams (inst, appointment, cell, workTime) {
    const god = this

    let fit = false
    const appointmentNewStart = cell.time
    const appointmentNewEnd = god
      ._toMoment(inst, cell.time)
      .add(appointment.duration, 'minutes')
      .format('HH:mm')

    if (!DateHelpers.isCorrectTime(appointmentNewStart, appointmentNewEnd)) {
      return false
    }

    workTime.forEach((range) => {
      if (fit) return
      fit = (appointmentNewStart >= range[0] && appointmentNewEnd <= range[1])
    })

    return fit
  },

  _getWorkTime (inst, date, userId, appStartTime, appEndTime) {
    const usersInCabinets = inst._usersInCabinets[date][userId]
    const hasWorkTimeEdgeIntersection = this._hasWorkTimeEdgeIntersection(usersInCabinets)

    const filteredUsersInCabinets = usersInCabinets
      .filter((cwt) => cwt.workTime.some((range) =>
        (appStartTime >= range[0] && appEndTime <= range[1]) ||
        hasWorkTimeEdgeIntersection
      ))

    const shifted = filteredUsersInCabinets.shift()

    return shifted?.workTime
  },

  _getCellData (elem) {
    return { ...elem.dataset, user_ids: elem.dataset.user_ids.split('_').map((id) => parseInt(id, 10)) }
  },

  /**
   * Проверка на пересечение рабочих периодов пользователя в разных кабинетах
   * @param {Array<{ cabinetId: number, workTime: [string, string][] }>} usersInCabinets
   * @return {boolean}
   * @private
   */
  _hasWorkTimeEdgeIntersection (usersInCabinets = []) {
    if (!usersInCabinets.length) { return false }

    const starts = new Set()
    for (const { workTime } of usersInCabinets) {
      for (const [start, end] of workTime) {
        starts.add(start)
        if (starts.has(end)) { return true }
      }
    }

    return false
  },
}
