import EventManagerExtension from './event_manager_extension'
import selectHelper from '../helpers/select_helper'
// DYH
import EntityManager from '../entities/EntityManager'
import editorHelper from './editorHelper'
import { checkDynamicElement, getDynamicElements } from './funcitons'

/**
 * CORE CLASS FOR RULE PROTOCOL ENTITIES
 */
const EventManager = function getParams (context, evt, editor, mode = false) {
  const self = this
  Object.keys(editorHelper).forEach(function (prop) {
    self[prop] = editorHelper[prop]
  })

  this.preventAction = (msg, node = false) => {
    this.preventEvents.push(msg)
    if (mode !== 'backend') {
      selectHelper.selectNode(node)
    }
  }

  this.mapElements = function (cb) {
    if (!this.elemStorage) return
    this.elemStorage.forEach(cb)
  }

  this.mapEditables = function (cb) {
    if (!this.editableStorage) return
    this.editableStorage.forEach(cb)
  }

  this.applicationParams = window.application.form_builder.current.globalMap
  this.entityManager = new EntityManager(window.application.form_builder.current.container)
  this.mode = mode
  this.ed = editor
  this.event = evt
  this.space = evt.keyCode === 32
  this.preventEvents = []
  this.enter = evt.keyCode === 13
  this.back = evt.keyCode === 8
  this.del = evt.keyCode === 46
  this.sel = editor.selection
  this.target = evt.target
  this.node = editor.selection.getNode()
  this.range = editor.selection.getRng()
  this.content = this.sel.getContent()
  this.startContainer = this.range.startContainer
  this.endContainer = this.range.endContainer
  this.previousSibling = this.range.startContainer.previousSibling
  this.nextSibling = this.range.startContainer.nextSibling
  this.startOffset = this.range.startOffset
  this.endOffset = this.range.endOffset
  this.collapsed = this.range.collapsed
  this.rangeEuqals = Boolean(this.startContainer === this.endContainer)
  this.debug = true

  //dynamic variables
  this.entity = this.entityManager.getEntity(this.node)
  this.elementType = this.entityManager.getEntity(this.node)?.type
  this.isVarialbe = this.elementType === 'variables'
  this.nodeElementContainer = getDynamicElements(this.node).length > 0
  this.inner = checkDynamicElement(this.node)
  this.ctrlKey = evt.ctrlKey
  this.prevSiblingStartRange = this.range.startContainer.previousSibling
  this.contentContainsElement = this.content.search(/.t-element/i) !== -1
  this.arrowKey = (
    this.event.keyCode &&
    this.event.keyCode >= 37 &&
    this.event.keyCode <= 40
  )

  this.digitalKey = (
    this.event.keyCode &&
    ((this.event.keyCode >= 48 && this.event.keyCode <= 57) ||
    this.event.code.indexOf('Numpad') !== -1))

  this.elemStorage = this.entityManager.entitiesCollection
  // define elements storages
  this.editableStorage =
    _.filter(this.elemStorage, (item) => this.checkEditable(item))

  this.switchStorage =
    _.filter(this.elemStorage, (item) => {
      const checkEntity = this.entityManager.getEntity(item)
      if (!checkEntity) return

      return checkEntity.switch
    })
  //definde key codes
  this.tabKey = evt.keyCode === 9
  this.rightKey = evt.code === 'ArrowRight'
  this.leftKey = evt.code === 'ArrowLeft'

  // range startContainerParams
  this.startAwaliableOffset = this.findNotEmptyIndexWithStart(this.startContainer)
  this.endAwaliableOffet = this.findNotEmptyIndexWithEnd(this.startContainer)
  this.stCBegindBogus = Boolean(
    this.startContainer.data &&
    this.startContainer.data.charCodeAt(0) === 65279)

  /**
  * define next node params (about range startContainer)
  */
  this.nextNode = this.findNextNode(this.startContainer)
  this.nextNodeDynamic = checkDynamicElement(this.nextNode)
  this.nextNodeDanger = Boolean(
    Boolean(
      this.endAwaliableOffet &&
      this.endAwaliableOffet.indexOf(this.endOffset) !== -1
    ) ||
    !this.endAwaliableOffet
  )

  /**
  * define prevnode params (about range startContainer)
  */
  this.prevNode = this.findPrevNode(this.startContainer)
  this.prevNodeDynamic = checkDynamicElement(this.prevNode)
  this.prevNodeDanger = Boolean(
    Boolean(
      this.startAwaliableOffset &&
      this.startAwaliableOffset.indexOf(this.startOffset) !== -1
    ) ||
    !this.startAwaliableOffset
  )

  this.prevNodeBlockDel = Boolean(
    this.prevNodeDynamic && this.prevNodeDanger && this.collapsed
  )

  /**
  * defind rootContainer and rooContainer params
  */
  this.rootContainer = this.findParentContainer(this.node)
  this.rootContainerPrevElem = this.rootContainer.previousElementSibling
  this.hasContainerBeforeRootContainer = Boolean(
    this.rootContainer &&
    this.rootContainerPrevElem &&
    this.rootContainerPrevElem.nodeName === 'P'
  )

  this.startRootContainer = Boolean(
    this.hasContainerBeforeRootContainer &&
    this.nextNodeDynamic &&
    this.prevNodeDanger &&
    this.nextNodeDanger &&
    !this.inner &&
    !this.prevNode
  )

  /**
   * Параметры верхнего контейнера (верхней строки)
   */
  this.topContainer = this.hasContainerBeforeRootContainer
    ? this.rootContainerPrevElem
    : undefined

  // Параметры последнего элемента верхнего контейнера
  this.lastChildOfTopContainer = this.topContainer?.lastChild
  this.isLastChildOfTopContainerDynamic = checkDynamicElement(this.lastChildOfTopContainer)
  this.isLastChildOfTopContainerBogus = this.checkBogusNode(this.lastChildOfTopContainer)

  // Параметры предпоследнего элемента верхнего контейнера
  this.penultimateChildOfTopContainer = this.lastChildOfTopContainer?.previousSibling
  this.isPenultimateChildOfTopContainerDynamic = checkDynamicElement(this.penultimateChildOfTopContainer)

  // Параметры конца верхнего контейнера
  this.isTopContainerEndsWithDynamicAndBogus = this.isLastChildOfTopContainerBogus && this.isPenultimateChildOfTopContainerDynamic
  this.isTopContainerEndsWithDynamicVisually = this.isLastChildOfTopContainerDynamic || this.isTopContainerEndsWithDynamicAndBogus

  this.isTopContainerEndsWithDynamicVisuallyAndCanMergeIt = Boolean(
    this.topContainer &&
    this.isTopContainerEndsWithDynamicVisually &&
    this.prevNodeDanger &&
    !this.prevNode &&
    this.collapsed
  )

  /**
  * define inner element params
  */
  this.leftBorderAchived = Boolean(
    this.startOffset === 0 ||
    Boolean(
      this.startContainer.data &&
      this.startContainer.data.charCodeAt(0) === 65279 &&
      this.startOffset === 1 &&
      this.inner
    )
  )

  this.leftBorderDanger = Boolean(this.leftBorderAchived && this.collapsed)

  /**
  * TODO
  */
  this.startRangeBogus = this.checkBogusNode(this.startContainer)
  this.startRangePreventelement = Boolean(
    this.nextNodeDynamic &&
    this.startContainer.nodeType === 3 &&
    this.rangeEuqals &&
    this.nextNodeDanger
  )

  if (context) {
    Object.keys(this).forEach(function (prop) {
      context[prop] = self[prop]
    })
  }
}

EventManager.prototype = EventManagerExtension

export default EventManager
