import { CREATE_WORK_TIME, DEFAULT_END_TIME, DEFAULT_START_TIME, REMOVE_WORK_TIME } from '../scheduleCommon/const.js'

import { populateAppointment } from '../scheduleCommon/utils.js'
import { DateHelpers } from '@/helpers/DateHelpers'

export default {

  _insertTo (inst, appointment, cellData) {
    const god = this

    if (typeof inst.options.onFree !== 'function') return

    const populatedApp = god._setAppointmentTime(inst, populateAppointment({ ...appointment, ...cellData }))
    populatedApp._worktime = god._getWorkTime(inst, populatedApp.date, populatedApp.user_id)
    inst.options.onFree(populatedApp, god._getParams(inst))

    god._clearState()
  },

  _moveFromWaitingList (inst, appointment, cell) {
    const god = this

    if (god._checkAppointmentParams(inst, appointment, cell)) {
      appointment.time = cell.time
      appointment.user_id = cell.user_id
      appointment.date = cell.date
      appointment.clinic_id = inst.options.currentClinicId

      inst.options.onMoveFromWaitingList(appointment)
    } else {
      god._showInvalidReceptionTimeModal(inst)
    }

    god._clearState()
  },

  _moveTo (inst, appointment, cell) {
    const god = this

    if (god._checkAppointmentParams(inst, appointment, cell)) {
      appointment.time = cell.time
      appointment.user_id = cell.user_id
      appointment.date = cell.date
      appointment.clinic_id = inst.options.currentClinicId

      god._patchAppointment(inst, appointment)
      god._clearState()

      inst.options.closeInsert()

      return true
    }
    god._showInvalidReceptionTimeModal(inst)
    god._clearState()

    return false
  },

  _cloneTo (inst, appointment, cell) {
    const god = this

    const clone = { ...appointment }

    if (god._checkAppointmentParams(inst, clone, cell)) {
      clone.id = null
      clone.time = cell.time
      clone.date = cell.date
      clone.user_id = cell.user_id
      clone.order_id = -1
      clone.status = 'need_approval'
      clone.appointment_source_id = 1
      clone.clinic_id = inst.options.currentClinicId

      const entryTypeIds = clone.entry_types.map((e) => e.id)

      if (entryTypeIds.length) {
        god._showCopyAppointmentWithServicesModal(
          inst,
          true,
          () => {
            clone.entry_type_ids = entryTypeIds
            god._createAppointment(inst, clone)
            god._showCopyAppointmentWithServicesModal(inst, false)
            inst.options.closeInsert()
          },
          () => {
            god._createAppointment(inst, clone)
            god._showCopyAppointmentWithServicesModal(inst, false)
            inst.options.closeInsert()
          })
      } else {
        god._createAppointment(inst, clone)
        inst.options.closeInsert()
      }
    } else { god._showInvalidReceptionTimeModal(inst) }

    god._clearState()
  },

  _checkAppointmentParams (inst, appointment, cell) {
    const god = this

    let fit = false
    const workTime = god._getWorkTime(inst, cell.date, cell.user_id)
    const appointmentNewStart = cell.time
    const appointmentNewEnd = god
      ._toMoment(inst, cell.time)
      .add(appointment.duration, 'minutes')
      .format('HH:mm')

    if (!DateHelpers.isCorrectTime(appointmentNewStart, appointmentNewEnd)) {
      return false
    }

    workTime.forEach((range) => {
      if (fit) return
      fit = (appointmentNewStart >= range[0] && appointmentNewEnd <= range[1])
    })

    return fit
  },

  _changeWorkTime (inst) {
    const god = this

    const data = inst.cache.changeWorkTimeData
    const timeRange = [data.startTime, data.endTime].sort()
    const workTime = god._getWorkTime(inst, data.date, data.userId)

    if (workTime.length === 0 || workTime.every((time) => time[1] <= timeRange[0] || time[0] >= timeRange[1])) {
      god._showCreateWorkTimeModal(
        inst,
        true,
        {
          onlyCreate: true,
          headerMessage: T.setting_working_time.format(data.user.short_name),
          message: T.set_the_time_as_working.format(timeRange[0], timeRange[1], data.formattedDate),
          applyEvent (response) {
            god._createWorkTimeRequest(
              inst,
              god._getCreateWorkTimeParams(data, timeRange, response.cabinetId, response.clinicId)
            )
            god._showCreateWorkTimeModal(inst, false)
          },
          rejectEvent: () => god._showCreateWorkTimeModal(inst, false),
        })
    } else if (workTime.some((time) => time[0] <= timeRange[0] && time[1] >= timeRange[1])) {
      god._showConfirmationModal(
        inst,
        true,
        T.removal_of_employee_time.format(data.user.short_name),
        T.set_the_time_as_non_working.format(timeRange[0], timeRange[1], data.formattedDate),
        () => {
          god._clearTimeRangeRequest(
            inst,
            god._getClearTimeRangeParams(inst, data, timeRange, inst.options.currentClinicId))
          god._showConfirmationModal(inst, false)
        },
        () => {
          god._showConfirmationModal(inst, false)
        })
    } else {
      god._showCreateWorkTimeModal(
        inst,
        true,
        {
          onlyCreate: false,
          headerMessage: T.working_time_change.format(data.user.short_name),
          message: T.change_time_from.format(timeRange[0], timeRange[1], data.formattedDate),
          applyEvent (response) {
            if (response.action === CREATE_WORK_TIME) {
              god._createWorkTimeRequest(
                inst,
                god._getCreateWorkTimeParams(data, timeRange, response.cabinetId, response.clinicId))
            } else if (response.action === REMOVE_WORK_TIME) {
              god._clearTimeRangeRequest(
                inst,
                god._getClearTimeRangeParams(inst, data, timeRange, inst.options.currentClinicId))
            }
            god._showCreateWorkTimeModal(inst, false)
          },
          rejectEvent: () => god._showCreateWorkTimeModal(inst, false),
        })
    }
    god._clearTimeRange(inst)
  },

  _updateGlobalTimeRange (inst) {
    const god = this

    let newStartTime, newEndTime
    inst._dates.forEach((date) => {
      inst._userIds.forEach((uid) => {
        inst._schedules[date][uid].forEach((timeRange) => {
          if (!newStartTime || timeRange[0] < newStartTime) newStartTime = timeRange[0]
          if (!newEndTime || timeRange[1] > newEndTime) newEndTime = timeRange[1]
        })
      })
    })

    for (const key in inst._appointmentsHashMap) {
      // redundant hasOwnProperty check
      const appointment = inst._appointmentsHashMap[key]
      if (!newStartTime || appointment.time < newStartTime) newStartTime = appointment.time
      if (!newEndTime || appointment._endTime > newEndTime) newEndTime = appointment._endTime
    }

    inst.globalStart = inst.globalRange.start = god._toMoment(inst, newStartTime || DEFAULT_START_TIME)
    inst.globalEnd = inst.globalRange.end = god._toMoment(inst, newEndTime || DEFAULT_END_TIME)
  },

  _getWorkTime (inst, date, userId) {
    return inst._schedules[date][userId]
  },

  _getCellData (elem) {
    return { ...elem.dataset, user_id: parseInt(elem.dataset.user_id) }
  },

  _getCellId (date, userId, time) {
    return `user-cell_${date}_${userId}_${time}`
  },

  _getCreateWorkTimeParams (data, timeRange, cabinetId, clinicId) {
    return {
      user_id: data.userId,
      date: data.date,
      work_time: `${timeRange[0]}-${timeRange[1]}`,
      cabinet_id: cabinetId === '' ? undefined : cabinetId,
      clinic_id: clinicId,
    }
  },

  _getClearTimeRangeParams (inst, data, timeRange, clinicId) {
    const userId = parseInt(data.userId, 10)
    const appointmentsIds =
      Object.values(inst._appointmentsHashMap).filter((appointment) => {
        if (appointment.date !== data.date || appointment.user_id !== userId) {
          return false
        }

        const appStart = appointment.time
        const appEnd = appointment._endTime
        const trStart = timeRange[0]
        const trEnd = timeRange[1]

        return (trStart <= appStart && trEnd >= appEnd) ||
               (trStart > appStart && trEnd < appEnd) ||
               (trStart < appStart && trEnd > appStart) ||
               (trStart < appEnd && trEnd > appEnd)
      }).map((appointment) => appointment.id)

    return {
      user_id: userId,
      date: data.date,
      time_range: JSON.stringify(timeRange),
      clinic_id: clinicId,
      appointmentsIds,
    }
  },
}
